import React from 'react'
import PropTypes from 'prop-types'
import { IconButton, Menu } from '@mui/material'
import MoreIcon from '@mui/icons-material/MoreHoriz'
import { bindTrigger, bindMenu, usePopupState } from 'material-ui-popup-state/hooks'

const More = (props) => (
  <IconButton size="small" {...props}>
    <MoreIcon />
  </IconButton>
)

const DropDown = ({ component, componentProps = {}, popupId, children, ...rest }) => {
  const popupState = usePopupState({ variant: 'popover', popupId })
  const Component = component || More
  return (
    <>
      <Component style={{ cursor: 'pointer' }} {...componentProps} {...bindTrigger(popupState)} />
      <Menu
        {...bindMenu(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        {...rest}
        onClick={popupState.close}
      >
        {children}
      </Menu>
    </>
  )
}

DropDown.propTypes = {
  component: PropTypes.func,
  popupId: PropTypes.string,
  children: PropTypes.node,
  componentProps: PropTypes.object,
}

export default DropDown
