const yup = require('yup')
const { requiredFields, labelify } = require('./helpers')

const shape = labelify({
  title: yup.string().ensure(),
  subtitle: yup.string().ensure(),
  url: yup.string().ensure(),
  createdAt: yup.date(),
  updatedAt: yup.date(),
  createdBy: yup.mixed(),
  updatedBy: yup.mixed(),
})

const customReportSchemaPartial = yup.object().shape(shape)
const customReportSchema = customReportSchemaPartial.shape(requiredFields(['name'])(shape))

module.exports = { customReportSchema, customReportSchemaPartial }
