import { props } from 'cerebral'
import { set } from 'cerebral/factories'
import { saveEntities } from '../factories'
import * as actions from './actions'

export const find = [
  //
  actions.find,
  saveEntities(),
]

export const findForList = [
  //
  set(props`useRouteParams`, true),
  actions.find,
  actions.setList,
  saveEntities(),
]

export const get = [
  //
  actions.get,
  saveEntities(),
]
